// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-disclosure-tsx": () => import("./../../../src/pages/disclosure.tsx" /* webpackChunkName: "component---src-pages-disclosure-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-camera-tsx": () => import("./../../../src/pages/links/camera.tsx" /* webpackChunkName: "component---src-pages-links-camera-tsx" */),
  "component---src-pages-links-music-tsx": () => import("./../../../src/pages/links/music.tsx" /* webpackChunkName: "component---src-pages-links-music-tsx" */),
  "component---src-pages-links-setup-audio-tsx": () => import("./../../../src/pages/links/setup/audio.tsx" /* webpackChunkName: "component---src-pages-links-setup-audio-tsx" */),
  "component---src-pages-links-setup-desk-chair-tsx": () => import("./../../../src/pages/links/setup/desk-chair.tsx" /* webpackChunkName: "component---src-pages-links-setup-desk-chair-tsx" */),
  "component---src-pages-links-setup-keyboard-mouse-tsx": () => import("./../../../src/pages/links/setup/keyboard-mouse.tsx" /* webpackChunkName: "component---src-pages-links-setup-keyboard-mouse-tsx" */),
  "component---src-pages-links-setup-monitors-tsx": () => import("./../../../src/pages/links/setup/monitors.tsx" /* webpackChunkName: "component---src-pages-links-setup-monitors-tsx" */),
  "component---src-pages-links-setup-pc-tsx": () => import("./../../../src/pages/links/setup/pc.tsx" /* webpackChunkName: "component---src-pages-links-setup-pc-tsx" */),
  "component---src-pages-links-setup-tsx": () => import("./../../../src/pages/links/setup.tsx" /* webpackChunkName: "component---src-pages-links-setup-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */)
}

